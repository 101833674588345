<template>
  <div class="loyalty content-container">
    <a-table
      bordered
      :rowKey="(e, index) => index"
      :columns="columns"
      :data-source="items"
      :pagination="{ defaultPageSize: 100, hideOnSinglePage: true }"
      :loading="loading"
    >
      <span class="loyalty__table_value" slot="value" slot-scope="item">
        <span>
          {{ item.value }}
        </span>
        <span>
          <a-button @click="onEdit(item)" type="link" icon="edit" />
        </span>
      </span>
    </a-table>
    <LoyaltyLevelsEditModal />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import LoyaltyLevelsEditModal from "@/components/loyalty/LoyaltyLevelsEditModal"

const rows = [
  { name: "% начисления", key: "bonus_percent" },
  { name: "% списания", key: "max_points_payment_percent" },
  { name: "истекает через (дней)", key: "points_lifetime" },
]

export default {
  name: "LoyaltyLevels",
  components: {
    LoyaltyLevelsEditModal,
  },
  data: () => ({}),
  async mounted() {
    await this.fetchLoyaltyLevels()
  },
  computed: {
    ...mapGetters({
      loyaltyLevels: "loyaltyLevels/loyaltyLevels",
      loading: "loyaltyLevels/loading",
    }),
    columns() {
      const firstColumn = {
        title: "",
        dataIndex: "name",
        key: "name",
      }

      const columns = this.loyaltyLevels.map(item => {
        return {
          title: item.name,
          dataIndex: item.id,
          key: item.id,
          scopedSlots: { customRender: "value" },
        }
      })
      return [{ ...firstColumn }, ...columns]
    },
    items() {
      let vm = this
      // retrieves values by key and creates an object:
      // return { "level": 1, "key": "bonus_percent", "value": 0.15 }
      const getVals = function (key, items) {
        let item = {}
        items.forEach(function (value) {
          if (value[key])
            item[value.id] = {
              level: value.id,
              key: key,
              value: vm.numberToPercent(key, value[key]),
            }
        })
        return item
      }
      // unpack the row template and add data for the columns
      return [...rows].map(item => {
        return {
          name: item.name,
          key: item.key,
          ...getVals(item.key, vm.loyaltyLevels),
        }
      })
    },
  },
  methods: {
    ...mapActions({
      fetchLoyaltyLevels: "loyaltyLevels/getLoyaltyLevels",
    }),
    ...mapMutations({
      setLoading: "loyaltyLevels/SET_LOADING",
      setEditModalVisible: "loyaltyLevels/SET_EDIT_MODAL_VISIBLE",
      setEditItem: "loyaltyLevels/SET_EDIT_ITEM",
    }),
    onEdit(item) {
      this.setEditItem(item)
      this.setEditModalVisible(true)
    },
    // отображение некоторых полей в виде процентов
    numberToPercent(key, value) {
      return key === "bonus_percent" || key === "max_points_payment_percent"
        ? value * 100
        : value
    },
  },
}
</script>

<style lang="scss">
.loyalty {
  &__table_value {
    width: 100%;
    display: flex;
    :first-child {
      flex-grow: 1;
    }
  }
}
</style>
