<template>
  <div>
    <a-modal
      :visible="editModalVisible"
      title="Программа лояльности"
      centered
      :maskClosable="false"
      cancel-text="Отмена"
      ok-text="Сохранить"
      @cancel="onCancel"
      @ok="onSubmit"
    >
      <a-alert
        style="margin-bottom: 10px"
        v-if="alertMsg"
        :message="alertMsg"
        type="warning"
        closable
      />
      <a-input
        :value="item.value"
        placeholder=""
        @input="setValue($event.target.value)"
        v-mask="'####'"
      />
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import { required, minLength } from "vuelidate/lib/validators"

export default {
  name: "LoyaltyLevelsEditModal",
  data: () => ({
    alertMsg: null,
  }),
  validations: {
    value: {
      required,
      minLength: minLength(4),
    },
  },
  async mounted() {
    this.value = this.item.value
  },
  computed: {
    ...mapGetters({
      editModalVisible: "loyaltyLevels/editModalVisible",
      item: "loyaltyLevels/editItem",
    }),
    columns() {
      return []
    },
  },
  methods: {
    ...mapActions({
      fetchLoyaltyLevels: "loyaltyLevels/getLoyaltyLevels",
      updateSingleLoyaltyLevel: "loyaltyLevels/updateSingleLoyaltyLevel",
      getLoyaltyLevels: "loyaltyLevels/getLoyaltyLevels",
    }),
    ...mapMutations({
      setEditModalVisible: "loyaltyLevels/SET_EDIT_MODAL_VISIBLE",
      setEditItem: "loyaltyLevels/SET_EDIT_ITEM",
      resetEditItem: "loyaltyLevels/RESET_EDIT_ITEM",
    }),
    setValue(value) {
      if (!this.validate(value)) {
        return false
      }

      let item = { ...this.item }
      item.value = value

      this.setEditItem(item)
    },
    validate(value) {
      if (value === "") {
        return false
      }

      this.alertMsg = null

      if (isNaN(value)) {
        this.alertMsg = "Введите корректные данные."
        return false
      }

      return true
    },
    async onSubmit() {
      let postData = []
      postData["level"] = this.item.level
      postData[this.item.key] = this.percentToNumber(
        this.item.key,
        this.item.value
      )
      await this.updateSingleLoyaltyLevel(postData)
    },
    onCancel() {
      this.setEditModalVisible(false)
      this.resetEditItem()
    },
    // переводим некотрые поля обратно из процента в число
    percentToNumber(key, value) {
      return key === "bonus_percent" || key === "max_points_payment_percent"
        ? value / 100
        : value
    },
  },
}
</script>

<style lang="scss"></style>
