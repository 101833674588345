<template>
  <LoyaltyLevels />
</template>

<script>
import LoyaltyLevels from "@/components/loyalty/LoyalityLevels"
export default {
  name: "LoyaltyLevels.vue",
  components: {
    LoyaltyLevels,
  },
}
</script>

<style lang="scss"></style>
